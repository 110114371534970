import React from 'react';
import HeroImage from '@images/home_page_hero.webp';
import HeroImageMobile from '@images/mobile_home_page_hero-min.webp';
import Image from '@components/molecules/ImageGatsby';
import { breakpoints } from '@helpers/constants';
import useBreakpoint from '@hooks/useBreakpoint';
import useToggle from '@hooks/useToggle';
import SEO from '@components/molecules/SEO';
import TopFixedSection from '@components/organisms/TopFixedSection';
import Layout from '@layouts/Layout';
import { graphql, navigate } from 'gatsby';
import AuthForm from '@components/organisms/AuthForm';
import { useUserAuth } from '@hooks/useUserAuth';
import { useDisablePageScroll } from '@hooks/useDisablePageScroll';

const LoginPage = ({
  data: {
    allWp: {
      nodes
    },
    wp: {
      siteSettings: {
        siteSettings: {
          menuText,
          menuLogo,
          loginFormTitle,
          userLoginForgotPasswordLabel,
          userLoginForm,
          userLoginFormErrorMessages,
          userLoginRegisterButton,
          userLoginInformation,
        },
      },
    },
  },
}) => {
  const {
    dispatch,
    loading,
    errorMessage,
    apolloError,
    isLoggedIn,
    submitFormHandler,
    state,
    forgottenPasswordLoading,
    isForgottenPassword,
    toggleForgottenPassword,
  } = useUserAuth(userLoginFormErrorMessages);

  const isMobile = useBreakpoint(breakpoints.sm);
  const { toggled, handleToggleClick } = useToggle(false);

  const inputChangeHandler = ({ target: { name, value } }) => {
    dispatch({
      type: name,
      payload: value,
    });
  };

  useDisablePageScroll();

  if (isLoggedIn) {
    return navigate('/blog');
  }

  return (
    <Layout
      translations={[]}
      currentLanguage="bg"
      lang="bg"
      hideParallax
      showFooter={false}
    >
      <SEO title="Login" translations={[]} />
      <Image image={isMobile ? HeroImageMobile : HeroImage} fill />
      <TopFixedSection
        menuText={menuText}
        menuLogo={menuLogo}
        handleToggleClick={handleToggleClick}
        toggled={toggled}
        generalSettingsUrl={nodes[0].allSettings.generalSettingsUrl}
      />
      <div className="h-screen backdrop-blur-[35px] backdrop-brightness-75">
        <AuthForm
          toggleForgottenPassword={toggleForgottenPassword}
          isForgottenPassword={isForgottenPassword}
          submitFormHandler={submitFormHandler}
          changeHandler={inputChangeHandler}
          formTitle={loginFormTitle}
          formContent={userLoginForm}
          errorMessage={errorMessage}
          apolloError={apolloError}
          loading={loading || forgottenPasswordLoading}
          sideLabel={userLoginInformation}
          sideButton={userLoginRegisterButton}
          state={state}
          forgottenPasswordLabel={userLoginForgotPasswordLabel}
        />
      </div>
    </Layout>
  );
};

export default LoginPage;

export const query = graphql`
  query LOGIN_PAGE {
    allWp {
      nodes {
        allSettings {
          generalSettingsUrl
        }
      }
    }
    wp {
      siteSettings {
        siteSettings {
          userLoginForgotPasswordLabel
          userLoginForm {
            button
            email
            password
          }
          userLoginFormErrorMessages {
            emptyField
            invalidField
            requiredField
          }
          userLoginInformation
          userLoginRegisterButton {
            title
            url
          }
          loginFormTitle
          menuText
          menuLogo {
            altText
            publicUrl
            mediaItemUrl
          }
        }
      }
    }
  }
`;
